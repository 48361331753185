@media (max-width: 992px) {
  .mobil-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: $clrss;
    width: 100%;
    height: 100%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    z-index: 996;
  }

  .arka-active {
    display: block;
    opacity: 1;
  }

  .mobil-nav--icon {
    display: block !important;
    height: 25px;
  }

  .mobil {
    display: block;
    position: fixed;
    top: 0;
    left: -320px;
    background: #fff;
    color: $renk_siyah;
    width: 300px;
    height: 100%;
    max-width: calc(100% - 80px);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    z-index: 9999;

    .nav-tabs {
      display: flex;
      position: absolute;
      bottom: 0;
      font-weight: 700;
      background: #fff;
      width: 100%;
      z-index: 99999;
      border-top: 1px solid $clrss;

      li {
        width: 50%;
        text-align: center;
      }
    }

    .tab {
      overflow: hidden;
    }

    .iletisim-bilgileri {
      padding: 30px;
      line-height: 1.7;

      .iletisim-box {
        font-size: 0.85rem;
        padding-left: 0;
      }

      span {
        opacity: 0.7;
      }

      a {
        color: $renk_siyah;
        text-decoration: underline;
      }
    }

    .tablinks {
      display: block;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 15px 0 6px;
      border-bottom: 3px solid transparent;
      opacity: 0.6;
      transition: all 0.3s;

      &:hover {
        border-color: $renk_sari;
        opacity: 1;
      }

      &.active {
        border-color: $renk_sari;
        opacity: 1;
      }
    }

    .tabcontent {
      padding-bottom: 45px;
      overflow-y: scroll;
      height: calc(100vh - 75px);
    }
  }

  .mobil-logo {
    padding: 30px 0;
    border-bottom: 1px solid $clrsb;

    img {
      display: block;
      margin: 0 auto;
      width: 160px;
    }

    .musteri-girisi {
      display: block;
      font-size: 14px;
      padding: 14px;
      margin: 0 auto;
      border-radius: 32px;
      width: 90%;
      text-decoration: none;

      i {
        right: 20px;
      }
    }
  }

  .mobil-baslik {
    display: block;
    font-size: 16px;
    font-weight: 500;
    background: $clrss-2;
    color: #fff;
    padding: 25px 15px;
    border: 0;
    transition: all 0.3s;
  }

  .mobil-menu-li {
    border-bottom: 1px solid $clrss;
  }

  .mobil-ana-menu {
    .mobil-menu-li {
      a {
        width: 100%;
      }

      i {
        margin-top: 2px;
        float: right;
      }
    }
  }

  .mobil-menu-link {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: $renk_siyah;
    padding: 20px 20px 20px 35px;
    transition: all 0.3s;
    text-decoration: none !important;

    i {
      margin-right: 6px;
    }

    &:focus,
    &:hover {
      color: $renk_sari;
    }

    &.active {
      color: $renk_sari;
    }
  }

  .mobil-alt-menu {
    display: none;
    background: #f4f4f4;
    padding: 15px 0;

    ul {
      padding: 10px 0;
      line-height: 1.4;
    }

    a {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: rgba($renk_siyah, .8);
      padding: 12px 12px 12px 50px;
      text-decoration: none;

      &:Hover {
        color: $renk_sari;
      }

      &.active {
        color: $renk_sari;
      }
    }
  }

  .mobil-active {
    left: 0;
  }

  .mobil-hr {
    background: $clrsb;
    margin-left: -50px;
    border: 0;
    height: 1px;
  }

  .container {
    max-width: 100%;
  }

  .slider-navigation {
    bottom: 15px;
  }

  .menu {
    display: none;
  }

  .sosyal {
    margin-top: 15px;
  }

  .btn-mobil,
  .mobil {
    display: block;
  }

  .btn-mobil {
    position: absolute;
    right: 0;
    top: 45px;
    z-index: 12;
    outline: transparent;
  }

  .slider-for .item img {
    max-width: 150%;
    width: 150%;
    margin-left: -30%;
  }

  .section-baslik {
    font-size: 2rem;
  }

  .footer {
    &__hakkimizda {
      width: 100%;
      margin-bottom: 60px;
      text-align: center;
    }

    &__menu {
      width: 25%;
    }

    &__bulten {
      width: 50%;
    }

    &--flex {
      flex-wrap: wrap;
    }
  }

  .projeler__galeri {
    margin-bottom: 30px;
  }

  .header-dil{
    position: relative;
    top: -35px;
  }

  .main-numbers{
    padding: 30px 0;
  }

  .numbers{
    margin: 30px 0;

    .fas, .far{
      font-size: 3rem;
    }
  }

  .numbers__number{
    font-size: 1.6rem;
  }

  .harita-resim{
    display: block;
    margin: 0 auto;
  }

  .tarihsel-slider .owl-nav{
    display: none;
  }
}
