@media (max-width:1200px) {
  .box--main {
    margin-top: 30px;
    height: auto;
    padding-bottom: 60px;
  }

  .card__baslik {
    font-size: 1.2rem;
  }

  .projeler__item {
    flex-wrap: wrap;

    strong {
      display: block;
      width: 100%;
      margin-bottom: 6px;
    }

    span {
      width: 100%;
    }
  }

  .slider-baslik {
    font-size: 2.5rem;
  }

  .slider-altyazi {
    font-size: 1.1rem;
  }

  .slider-for .item img {
    max-width: 130%;
    width: 130%;
    margin-left: -15%;
  }

  .projeler__item {
    span {
      text-align: left;
    }
  }

  .proje-sayfa {
    .container {
      max-width: 100%;
    }
  }

  .menu__link{
    font-size: .9rem;
  }

  .header{
    .container{
      max-width: 100%;
    }
  }

  .header-dil{
    font-size: .8rem;
  }

  .header__top{
    padding: 15px 0;
  }

  .menu{
    padding-top: 15px;
    padding-bottom: 0;
  }
}
