$renk_sari: #164BA0;
$renk_yazi: #505050;
$renk_gri: #8B8B8B;
$renk_siyah: #161616;
$renk_kirmizi: #E30613;
$clr_cizgi: rgba(43, 41, 45, 0.2);
$clrss: rgba(0, 0, 0, .1);
$clrss-2: rgba(0, 0, 0, .2);
$clrss-3: rgba(0, 0, 0, .3);
$clrss-4: rgba(0, 0, 0, .4);
$clrsb: rgba(255, 255, 255, .1);
$clrsb-2: rgba(255, 255, 255, .2);
$clrsb-3: rgba(255, 255, 255, .3);
$clrsb-4: rgba(255, 255, 255, .4);
$clrsb-8: rgba(255, 255, 255, .8);

.renk--sari{
  color: $renk_sari;
}
