@media (max-width: 768px) {
  .top-iletisim {
    &:first-child {
      display: none;
    }
  }

  .slider-arrow {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
  }

  .referanslar-arrows {
    width: 66px;
  }

  .hakkimizda--flex {
    flex-wrap: wrap;
  }

  .card-hakkimizda {
    width: 100%;

    .yazi {
      width: 50%;
    }
  }

  .hakkimizda-resim {
    bottom: auto;
    right: 15px;
    left: auto;
    display: block;
    margin-left: auto;
    z-index: 2;
    width: 40%;
    margin-top: -260px;
  }

  .urun__baslik {
    font-size: 0.9rem;
  }

  .urun__aciklama {
    font-size: 0.85rem;
  }

  .footer {
    &__menu {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }

    &__bulten {
      width: 100%;
      text-align: center;
    }

    .bulten {
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .copyright {
    text-align: center;
  }

  .banner__baslik {
    font-size: 1.8rem;
  }

  .geri--btn {
    display: inline-block;
  }

  .sayfa-nav {
    .btn {
      display: none;
    }
  }

  .filtre a {
    font-size: 0.9rem;
    margin: 0;
  }

  .slider-baslik {
    font-size: 1.8rem;
  }

  .slider-altyazi {
    font-size: 0.9rem;
  }

  .footer__adres,
  .footer__menu {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .slider-nav {
    width: 100%;
  }

  .slider-baslik {
    font-size: 1.8rem;
  }

  .slider-altyazi {
    font-size: 0.9rem;
  }

  .slider-for .item img {
    max-width: 180%;
    width: 180%;
    margin-left: -45%;
  }

  .slider-navigation__arrows{
    display: none;
  }

  .section-baslik{
    font-size: 1.4rem;
  }

  .baslik{
    font-size: 1.1rem;
  }
}
