@media (max-width: 576px) {
  .slider-nav {
    width: calc(100% - 120px);
  }

  .slider-navigation {
    bottom: -45px;
  }

  .hakkimizda-resim {
    display: none;
  }

  .card-hakkimizda .yazi {
    width: 100%;
  }

  .hizmet__resim {
    float: none;

    img {
      width: 100%;
    }
  }

  .filtre {
    border: none;

    a {
      display: block;
      width: 100%;
      border: 1px solid #dfdfdf;
      margin: 15px 0;
      box-shadow: 0 2px 12px -4px $clrss, 0 2px 12px -4px $clrss;

      &:After {
        display: none;
      }

      &.active,
      &:hover {
        box-shadow: 0 8px 18px $clrss, 0 8px 18px $clrss;
        background: $renk_sari;
        border-color: $renk_sari;
        color: #fff;
      }
    }
  }

  .slider-for .item img {
    max-width: 220%;
    width: 220%;
    margin-left: 0;
  }

  .slider-yazi {
    top: 60%;
  }

  .slider-baslik {
    font-size: 1.4rem;
  }

  .slider-wrapper {
    .slider-navigation {
      display: none;
    }

    .owl-dots {
      margin-top: -35px !important;
      position: relative;
      z-index: 2;
    }
  }

  .logo{
    width: 150px;
  }
}
@media (max-width: 390px) {
  .header-dil .sosyal{
    display: none;
  }
  .top-iletisim {
    display: none;
  }

  .btn-mobil {
    top: 20px;
  }

  .header__menu {
    justify-content: flex-end;
  }

  .header__top {
    padding: 0;
  }

  .logo {
    margin-top: 10px;
  }
}
