$mont: 'Montserrat', sans-serif;

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  font-family: $mont;
  line-height: 1.5;
}

img{
  max-width: 100%;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

button,
input,
select,
textarea {
  font-family: $mont;
}

.btn {
  background: $renk_sari;
  font-weight: 500;
  padding: 10px 12px;
  display: inline-block;
  color: #fff;
  border: none;
  text-decoration: none;
  @extend .efekt;
  line-height: 1.2;
  vertical-align: middle;
  position: relative;
  font-size: .9rem;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 100%;
    @extend .efekt;
    height: 100%;
    background: none;
    border: 1px solid $renk_sari;
  }

  &:hover {
    background: #196CF0;
    color: #fff;

    &:After {
      border-color: #196CF0;
    }
  }

  &--normal{
    &:After{
      display: none;
    }
  }
}

.noscroll {
  overflow: hidden;
}

.tab-panel {
  display: none;
}

.tab-active {
  display: block;
  animation: fadeIn 0.5s;
}

.tabcontent {
  animation: fadeIn 0.5s;
}

.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
