@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'owl', 'lightbox';

.btn-mobil,
.mobil {
  display: none;
}

.header {
  position: relative;
  z-index: 3;
  border-bottom: 1px solid #dfdfdf;
  background: #fff;

  &__top {}

  &--flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__iletisim {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }
}

.logo {}

.top-iletisim {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-left: 45px;
  }

  &:first-child {
    i {
      font-size: 2rem;
    }
  }

  &__baslik {
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    color: rgba(#FFF,.8);
  }

  &__link {
    font-size: 0.95rem;
    font-weight: 500;
    color: $renk_sari;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
  }

  &__wrapper {}

  i {
    font-size: 1.6rem;
    color: $renk_sari;
    margin-right: 15px;
  }
}

.header-dil {
  text-align: right;
  padding-top: 15px;

  .sosyal {
    display: inline-block;
    margin-right: 30px;
  }
}

.menu {
  padding-top: 30px;
  padding-bottom: 15px;

  &__list {}

  &__item {
    display: inline-block;
    position: relative;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .alt-menu {
        display: block;
      }
    }
  }

  &__link {
    font-size: 1rem;
    font-weight: 500;
    color: $renk_sari;
    text-decoration: none;
    @include efekt(color);

    .fas {
      color: $renk_kirmizi;
    }

    &:hover {
      color: $renk_kirmizi;
    }
  }
}

.menu__item:hover > a {
  color: $renk_kirmizi;
}

.alt-menu {
  @extend .bxs;
  @extend .bdrs;
  display: none;
  position: absolute;
  background: #fff;
  padding: 15px;
  margin-top: 15px;
  width: 200px;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    background: none;
    width: 100%;
    height: 30px;
  }

  li {
    display: block;
  }

  a {
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
    color: $renk_sari;
    padding: 8px 0;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_kirmizi;
    }
  }
}

.sosyal {
  &__link {
    font-size: 0.9rem;
    font-weight: 500;
    color: $renk_kirmizi;
    margin-left: 10px;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
  }
}

.slider-wrapper {
  position: relative;
  z-index: 1;
}

.slider-for {
  .item {
    img {
      width: 100%;
    }
  }

  .owl-nav {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9999;
  }
}

.slider-nav {
  display: inline-block;
  width: 750px;
  margin: 0 auto;

  .owl-item {
    cursor: pointer;
    border: 2px solid transparent;
    width: 100px;
    @include efekt(border-color);

    &:hover {
      border-color: rgba($renk_sari, .6);
    }

    &.current {
      border-color: $renk_sari;
    }
  }
}

.slider-navigation {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  z-index: 2;

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @extend .dikeyAbsolute;
    z-index: 3;
    width: calc(100% - 30px);
    left: 15px;
  }

  &--flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
}

.slider-arrow {
  @extend .efekt;
  background: $clrsb-2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $renk_sari;
  margin: 0 2px;
  border: 1px solid $renk_sari;
  width: 34px;
  height: 34px;
  text-decoration: none;

  &:hover {
    background: $renk_sari;
    color: #fff;
  }
}

.slider-wrapper .slider-arrow {
  height: 118px;
  color: #fff;
}

.referanslar {
  // background: url("../img/ref-bg.jpg") no-repeat top right;
  background-size: cover;
  padding: 120px 0;
}

.section-baslik {
  display: block;
  font-size: 3rem;
  font-weight: 800;
  color: $renk_gri;
}

.referanslar-slider {}

.referanslar-arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 84px;

  .slider-arrow {
    margin: 0;
  }
}

.urun {
  position: relative;

  a {
    display: block;
    text-decoration: none !important;
  }

  &__bg {
    @extend .efekt;
    position: absolute;
    top: 0;
    left: 0;
    background: $clrss-3;
    padding: 15px;
    width: 100%;
    height: 100%;

    &:Hover {
      background: $clrss;
      box-shadow: inset 0 0 0 4px $renk_sari;
      text-shadow: 1px 1px 4px #000;
    }
  }

  &__baslik {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }

  &__aciklama {
    font-size: 1rem;
    font-weight: 300;
    color: rgba(#FFF,.7);
  }

  img {
    width: 100%;
    vertical-align: middle;
  }
}

.box {
  position: relative;
  background: #fff;
  padding: 30px;
  border: 10px solid $renk_gri;
  text-align: center;
  height: calc(100% - 56px);

  &__baslik {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    color: $renk_siyah;
  }

  &__yazi {
    font-size: 0.9rem;
    color: $renk_yazi;
  }

  .btn {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 160px;
  }
}

.main-hakkimizda {
  // background: url("../img/banner.jpg");
  background: url("../img/ref-bg.jpg") no-repeat top right;
  background-attachment: fixed;
  background-size: cover;
  padding: 100px 0;
}

.card-hakkimizda {
  position: relative;
  background: #fff;
  box-shadow: 0 4px 36px rgba(0, 0, 0, 0.25);
  padding: 30px;
  z-index: 2;
}

.hakkimizda--flex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .section-baslik {
    color: $renk_sari;
  }
}

.hakkimizda-resim {
  position: relative;
  bottom: -62px;
  left: -28px;
}

.baslik {
  display: block;
  font-size: 1.3rem;
  font-weight: 600;

  &--medium {
    font-size: 1.5rem;
  }

  &--big {
    font-size: 1.8rem;
  }

  &--sari {
    color: $renk_sari;
  }
}

.yazi {
  color: $renk_yazi;
  line-height: 1.8;
}

.main-hizmetlerimiz {
  background: url("../img/bg.jpg") no-repeat bottom right;
  background-size: cover;
  padding: 100px 0;
}

.main-haberler {
  background: #fff;
  padding: 100px 0;
}

.haber {
  background: #fff;
  padding: 15px;

  &--ic {
    border: 1px solid #f4f4f4;
  }

  &__resim {
    position: relative;
    height: 270px;
  }

  &__kategori {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 0.8rem;
    background: $renk_siyah;
    color: rgba(#FFF,.8);
    padding: 6px 12px;
    letter-spacing: 1px;
  }

  &__baslik {
    display: block;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 12px;
    min-height: 52px;
  }

  &__kisa {
    @extend .yazi;
    font-size: 0.9rem;
  }

  &__alt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-top: 40px;
  }

  &__tarih {
    font-size: 0.8rem;
    color: $renk_yazi;
  }

  .btn {
    font-size: 0.8rem;
  }
}

.btn-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: $renk_siyah;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer {
  background: $renk_siyah;
  padding-top: 60px;
  padding-bottom: 15px;

  &__adres {
    color: rgba(#FFF,.6);
    width: 20%;
    padding-right: 15px;

    .footer-baslik {
      font-size: 1rem;
      line-height: 1;
      margin-bottom: 18px;
    }

    span {
      display: block;
      margin: 4px 0;
      font-size: 0.8rem;
      line-height: 2;
    }
  }

  &--flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__hakkimizda {
    font-size: 0.8rem;
    color: rgba(#FFF,.5);
    width: 20%;
  }

  &__menu {
    width: 20%;
  }

  &__menu-list {
    li {
      display: block;
      margin-bottom: 4px;
    }

    a {
      display: block;
      font-size: 0.9rem;
      color: rgba(#FFF,.7);
      padding: 6px 0;
      text-decoration: none;
      @include efekt(color);

      &:hover {
        color: $renk_sari;
        text-decoration: underline;
      }

      .fas {
        color: $renk_sari;
        margin-right: 6px;
      }
    }
  }

  &__bulten {
    width: 28%;

    .yazi {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      color: rgba(#FFF,.7);
    }
  }
}

.footer-iletisim {
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(#FFF,.7);
  margin: 10px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer-baslik {
  display: block;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}

.bulten {
  position: relative;

  &__txt {
    @extend .efekt;
    display: block;
    background: $clrsb;
    color: #fff;
    padding: 0 90px 0 6px;
    border: 1px solid $renk_sari;
    line-height: 40px;
    width: 100%;
    height: 40px;

    &:focus,
    &:hover {
      box-shadow: 0 0 0 4px rgba($renk_sari, .4);
      outline: transparent;
    }

    &::placeholder {
      color: rgba(#FFF,.5);
    }
  }

  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-weight: 600;
    background: $renk_sari;
    cursor: pointer;
    padding: 0 10px;
    border: none;
    line-height: 40px;
    height: 40px;
    @include efekt(color);

    &:hover {
      color: #fff;
    }
  }
}

.footer-sosyal {
  margin-top: 40px;

  &__link {
    font-size: 1.6rem;
    @include efekt(color);
    color: rgba(#FFF,.9);
    margin-right: 15px;
    text-decoration: none;

    &:hover {
      color: $renk_sari;
    }
  }
}

.copyright {
  font-size: 0.8rem;
  color: rgba(#FFF,.74);
  padding-top: 15px;
  margin-top: 30px;
  border-top: 1px solid $clrsb;
}

hr {
  background: $clrss;
  border: none;
  height: 1px;
}

.banner {
  position: relative;
  padding-top: 220px;
  padding-bottom: 60px;
  margin-top: -144px;

  &__baslik {
    font-size: 2.8rem;
    font-weight: 700;
    color: #fff;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $renk_sari;
    text-shadow: 2px 2px 6px $clrss-2;
  }

  &:After {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: $clrss-4;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.breadcrumb {
  &__link {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgba(#FFF,.8);
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }

    &:first-child:before {
      display: none;
    }

    &:before {
      content: '/';
      display: inline-block;
      color: rgba(#FFF,.6) !important;
      margin: 0 10px;
    }
  }
}

.iletisim {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #252525;
  margin-bottom: 15px;
}

.form-control {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  background-clip: padding-box;
  background-color: #fff;
  color: #495057;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  line-height: 1.5;
  outline: transparent;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  color: #495057;
  border-color: #80bdff;
  outline: 0;
}

.form-label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #373535;
  margin-bottom: 8px;
}

.card {
  @extend .bxs;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;

  &__icon {
    display: inline-block;
    background: $renk_sari;
    color: #fff;
    padding: 15px;
    border-radius: 50%;
    text-align: center;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
    }
  }

  &__baslik {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 15px 0;
  }

  &__yazi {
    font-size: 0.85rem;
    font-weight: 500;
    color: $renk_yazi;
    line-height: 2;
  }
}

.hizmet {
  &__resim {
    margin-bottom: 30px;
    margin-right: 30px;
    float: left;
  }

  &__yazi {}
}

.sayfa-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.geri--btn {
  display: none;
}

.proje {
  animation: fadeIn 0.5s;
}

.filtre {
  margin-bottom: 50px;
  border-bottom: 3px solid #dfdfdf;
  text-align: center;

  a {
    @extend .efekt;
    display: inline-block;
    position: relative;
    position: relative;
    font-size: 1.1rem;
    font-weight: 600;
    color: #868686;
    padding: 15px;
    margin: 0 12px;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      background: $renk_sari;
      margin: 0 auto;
      width: 0;
      height: 3px;
      z-index: 2;
      @include efekt(width);
    }

    &.active,
    &:hover {
      color: $renk_sari;

      &:After {
        width: 100%;
      }
    }
  }
}

.projeler {
  &__detay {}

  &__item {
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    line-height: 2;
    padding: 15px;
    background: #f4f4f4;
    margin-bottom: 15px;
    @extend .bdrs;

    i {
      margin-right: 8px;
      color: $renk_sari;
    }

    strong {
      width: 180px;

      &:after {
        content: ':';
        margin-left: 10px;
      }
    }

    span {
      font-weight: 500;
      text-align: right;
    }
  }

  &__galeri {}

  &__yazi {
    font-size: 0.9rem;
    font-weight: 400;
  }
}

.proje-slider {
  position: relative;

  .slider-navigation__arrows {
    @extend .dikeyAbsolute;
    margin-left: 15px;
    width: calc(100% - 30px);
    z-index: 10;
  }

  .slider-nav {
    width: 100%;

    .owl-stage-outer {
      padding: 15px 3px;
      margin: -3px;
    }

    .owl-item {
      @extend .efekt;
      border: none !important;
      width: 200px;

      &.current,
      &:hover {
        box-shadow: 0 0 0 3px $renk_sari;
      }
    }
  }
}

.referans-logo {
  filter: grayscale(1);
  @include efekt(filter);

  &:Hover {
    filter:grayscale(0);
  }
}

.paging {
  &__item {
    @extend .bdrs;
    @extend .efekt;
    display: inline-block;
    font-weight: 500;
    background: #f4f4f4;
    color: $renk_siyah;
    margin: 4px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    text-decoration: none !important;

    &.active,
    &:Hover {
      background: $renk_sari;
      color: #fff;
    }
  }
}

.haber-icerik {
  font-size: 0.9rem;
  line-height: 1.8;
}

.haber-tarih {
  font-size: 0.8rem;
  color: $renk_yazi;
}

.haber-alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #dfdfdf;
}

#big .item:Before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slider-yazi {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-50%);
  text-shadow: 2px 2px 4px rgba(0,0,0,.4);
  z-index: 9;
}

.slider-baslik {
  display: block;
  position: relative;
  font-size: 3.5rem;
  font-weight: 800;
  color: #fff;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.slider-baslik:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: $renk_sari;
  width: 80px;
  height: 4px;
}

.slider-altyazi {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

.header-dil {
  &__ayrac {
    color: $renk_sari;
    display: inline-block;
    margin: 0 4px;
  }

  a {
    color: $renk_sari;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .sosyal {
    a {
      color: $renk_kirmizi;
    }
  }
}

#big .item:Before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  z-index: 1;
}

.slider-yazi {
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 2px 2px 4px rgba(0,0,0,.4);
}

.slider-baslik {
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
  display: block;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
}

.slider-baslik:after {
  content: '';
  height: 4px;
  background: #f2aa00;
  position: absolute;
  width: 80px;
  left: 0;
  bottom: 0;
}

.slider-altyazi {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: block;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $renk_sari;
}

.main-numbers {
  padding: 70px 0;
  background-color: #164ba0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23154899' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23144593' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2314428d' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23133f86' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23123c80' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23133f86' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2314428d' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23144593' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23154899' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23164ba0' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.numbers {
  text-align: center;

  .far,
  .fas {
    color: #fff;
    font-size: 4rem;
    display: block;
    margin-bottom: 15px;
  }

  &__number {
    font-size: 2rem;
    font-weight: 800;
    display: block;
    color: #fff;
  }

  &__baslik {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(#FFF,.7);
  }
}

.tarihsel {
  margin-top: 150px;

  .owl-stage-outer{
    padding: 30px;
  }

  .item{
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    background: #fff;
    @extend .bdrs;
    padding: 30px 15px;
    @extend .bxs;
  }

	&__tarih {
    margin-bottom: 15px;
    color: $renk_sari;
	}

	&__baslik {
    display: block;
	}

}

.tarihsel-slider {
  .owl-nav{
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    @extend .dikeyAbsolute;

    button{
      display: inline-block;
      outline: none !important;

      &:Hover{
        background: $renk_sari !important;
      }
    }
  }
}

.harita-yazi {
  height: 100%;
  @extend .dikeyFlex;
  flex-wrap: wrap;
  color: #fff;
}

.harita-ust {
  display: block;
  width: 100%;
  text-align: center;
}

.harita-baslik {
  display: block;
  width: 100%;
  font-size: 2.6rem;
  font-weight: 800;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: #fff;

  &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 4px;
    background: $renk_kirmizi;
    width: 60px;
    bottom: 0;
  }
}

.harita-alt-baslik {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
}

.harita-text{
  display: block;
  width: 100%;
  font-weight: 500;
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
}

.main-harita{
  background-color: #164ba0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23154899' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23144593' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2314428d' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23133f86' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23123c80' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23133f86' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2314428d' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23144593' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23154899' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23164ba0' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  padding: 70px 0;
}

@import 'style1200', 'style992', 'style768', 'style576';
